import { useState } from "react";
import { BsTrash } from "react-icons/bs";
import { formatoBrasileiro } from "../../../util/formatacaoMoeda";
import { PrimeiraLetraMaiuscula } from "../../../util/primeiraLetraMaiuscula";
import { ConfirmDialog } from "primereact/confirmdialog"; // For <ConfirmDialog /> component
import { GravaLocal } from "../../../../conexao/localstorage";
import { API } from "../../../../conexao/api";
import { Imagem } from "../../../imagem/imagem";
import { ValidaEstoqueProduto } from "../../../util/validaestoqueproduto";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import { CorFonte, CorFundo } from "../../../util/validaAbertura";

export function ProdutoCarrinho(prop) {
  //////////console.log(prop);
  const [visible, setVisible] = useState(false);
  const [ForaEstoque, setForaEstoque] = useState(false);
  const [Ponto, setPonto] = useState(0);
  const [QuantidadeResgate, setQuantidadeResgate] = useState(0);
  const [ValorProduto, setValorProduto] = useState(0);
  const confirm = () => {
    // Lógica a ser executada quando o usuário confirmar

    // prop.DadosCarrinho.splice(prop.index, 1);

    // GravaLocal('produtos_selecionados', JSON.stringify(prop.DadosCarrinho));
    prop.deletar(prop.index);
    setVisible(false);
    ////////////console.log('Ver por que nao ta atualizando quando remove')
  };

  const cancel = () => {
    // Lógica a ser executada quando o usuário cancelar
    setVisible(false);
  };

  const Deletar = () => {
    setVisible(true);
  };

  API.get(
    "generica.php?tabela=ws_itens&where=id = " + prop.produto["id_produto"]
  ).then(function (response) {
    var foraestoque = false;
    // //console.log(response.data[0])
    setPonto(response.data[0].fidelidade_valor);
    setValorProduto(response.data[0].preco_item);

    if (parseInt(response.data[0]["disponivel"]) == 1) {
      foraestoque = false;
    } else {
      foraestoque = true;
    }
    // foraestoque = true;
    if (foraestoque != ForaEstoque) {
      setForaEstoque(foraestoque);
      prop.ForaEstoque();
    }
  });

  function Add() {
    if (prop.PontosDisponivel() >= Ponto) {
      prop.AddPontoUsado(Ponto, ValorProduto);
      setQuantidadeResgate(QuantidadeResgate + 1);
    }
  }

  function Remove() {
    if (QuantidadeResgate > 0) {
      prop.RemovePontoUsado(Ponto, ValorProduto);
      setQuantidadeResgate(QuantidadeResgate - 1);
    }
  }

  function HabilitadoPonto() {
    if (!prop.fidelidade) {
      return false;
    }
    if (Ponto > 0) {
      if (prop.PontosDisponivel() >= Ponto) {
        return true;
      }
    }

    if (QuantidadeResgate > 0) {
      return true;
    }
    return false;
  }

  return (
    <>
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message={
          "Deseja excluir o " +
          prop.produto.quantidade_selecionada +
          "x - " +
          PrimeiraLetraMaiuscula(prop.produto.nome_produto) +
          " " +
          formatoBrasileiro.format(prop.SomaTotal(prop.produto)) +
          "?"
        }
        header="Excluir"
        icon="pi pi-trash"
        accept={confirm}
        reject={cancel}
        rejectLabel="Cancelar"
        acceptLabel="Excluir"
      />
      <li className="product-item">
        <div style={{ marginRight: "10px" }}>
          {/* <img className="product-image" src={prop.produto.url_foto} /> */}

          {ForaEstoque ? (
            <Imagem
              altura="100px"
              largura="100px"
              foto={prop.produto.url_foto}
              id={prop.produto.id_produto}
              ifood={prop.produto.url_foto_ifood}
              descricao={"Fora de Estoque"}
            />
          ) : (
            <Imagem
              altura="100px"
              largura="100px"
              foto={prop.produto.url_foto}
              id={prop.produto.id_produto}
              ifood={prop.produto.url_foto_ifood}
            />
          )}
        </div>
        <div className="product-info">
          <h3 className="product-title">
            {PrimeiraLetraMaiuscula(prop.produto.nome_produto)}
          </h3>
          <p className="product-description">
            {PrimeiraLetraMaiuscula(prop.Descricao(prop.produto))}
          </p>
          <p className="product-price-unitario">
            {prop.produto.quantidade_selecionada} x{" "}
            {formatoBrasileiro.format(prop.SomaTotal(prop.produto))}
          </p>
          <p className="product-price-total">
            Total{" "}
            {formatoBrasileiro.format(
              prop.SomaTotal(prop.produto) * prop.produto.quantidade_selecionada
            )}
          </p>
          {HabilitadoPonto() ? (
            <div style={{ marginTop: "50px" }}>
              <p style={{ fontSize: "0.9rem", color: "rgb(31, 183, 108)" }}>
                Quantidade que deseja resgatar com seus pontos
              </p>
              <div
                style={{
                  width: "100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IoIosRemove
                  color={QuantidadeResgate > 0 ? CorFundo() : "transparent"}
                  size={24}
                  onClick={Remove}
                />
                <p
                  style={{
                    width: "33%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: 0,
                  }}
                >
                  <b>{QuantidadeResgate}</b>
                </p>
                <div
                  style={{
                    width: "33%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IoIosAdd
                    color={
                      prop.PontosDisponivel() >= Ponto
                        ? CorFundo()
                        : "transparent"
                    }
                    size={24}
                    onClick={Add}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <span className="delete-icon">
          <BsTrash size={25} onClick={Deletar} />
        </span>
      </li>
    </>
  );
}
