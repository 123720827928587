import { useState } from "react";
import { API } from "../../../conexao/api";
import { GravaLocal } from "../../../conexao/localstorage";
import { BuscaLocal } from "../../../conexao/localstorageBusca";
import { formatoBrasileiro } from "../../util/formatacaoMoeda";
import { FotoProduto } from "../../util/imagemProduto";
import { Notificacao } from "../../util/notificacao";
import { PrimeiraLetraMaiuscula } from "../../util/primeiraLetraMaiuscula";
import { CategoriaAdicional } from "./Adicional/categoriaAdicional";
import "./produto.css";
import { InputTextarea } from "primereact/inputtextarea";
import "primereact/inputtextarea";
import { BsBagDash, BsBagPlus } from "react-icons/bs";
import { CorFonte, CorFundo, MensalidadePaga } from "../../util/validaAbertura";
import { Imagem } from "../../imagem/imagem";
import { ImagemProduto } from "../../imagem/imagemProduto";
import { exit } from "process";
import ReactPixel from "react-facebook-pixel";
import { PIXELFACEBOOK } from "../../util/datahora";
import { FidelidadeProduto } from "../../fidelidade/produto/fidelidadeProduto";
import { IoIosAdd, IoIosRemove } from "react-icons/io";

var ValorAdicional = 0;
var ValorProduto = 0;
var ValorTotal = 0;
var Quantidade = 1;
var DadosSelecionado = [];
DadosSelecionado.push();

export function Produto(props) {
  const [QTD, setQtd] = useState(1);

  try {
    var dadosLocalProduto = JSON.parse(BuscaLocal("produto_selecionado_dados"));
  } catch (error) {
    var dadosLocalProduto = [];
  }

  const [value, setValue] = useState("");
  const MAX_CHARS = 200;

  const handleChange = (e) => {
    DadosSelecionado[0]["observacao_adicionada"] = e.target.value;
    document.getElementById("contador-caracteres-observacao").textContent =
      MAX_CHARS - e.target.value.length + " caracteres restantes";
  };

  let DadosPizzaAlterado = [];

  const [dados, setDados] = useState(dadosLocalProduto);
  const [dadosAdicionales, setDadosAdicionales] = useState([]);
  const [dadosPizza, setDadosPizza] = useState([]);
  const [isAdd, setIsAdd] = useState(true);
  const [QuantidadeLoad, setQuantidadeLoad] = useState(0);

  function SomaQuantidade() {
    setQuantidadeLoad((prevQuantidadeLoad) => prevQuantidadeLoad + 1);
  }

  function SomaPizza() {
    var valor = parseFloat(BuscaLocal("soma_sabores"));
    var tipo = parseFloat(BuscaLocal("pizza_tipo_valor"));
    var quantidade = parseFloat(BuscaLocal("soma_sabores_selecionado"));

    if (tipo == 0) {
      valor = parseFloat(valor) / parseFloat(quantidade);
    }

    if (!parseFloat(valor) > 0) {
      valor = 0;
    }

    return parseFloat(valor);
  }

  ValorProduto = parseFloat(BuscaLocal("produto_selecionado_valor"));
  const AtualizaValor = () => {
    ValorTotal =
      (parseFloat(ValorProduto) + parseFloat(SomaPizza())) *
        parseFloat(Quantidade) +
      parseFloat(ValorAdicional) * parseFloat(Quantidade);

    try {
      document.getElementById("total-produto").textContent =
        " " + formatoBrasileiro.format(ValorTotal);
      document.getElementById("qtd-produto").textContent = Quantidade;
    } catch (error) {}
  };

  function increment() {
    Quantidade = parseInt(Quantidade) + 1;
    DadosSelecionado[0].quantidade = Quantidade;

    AtualizaValor();
  }

  function decrement() {
    if (Quantidade > 1) {
      Quantidade = parseInt(Quantidade) - 1;
      DadosSelecionado[0].quantidade = Quantidade;

      AtualizaValor();
    }
  }

  const add = (valor) => {
    ValorAdicional = parseFloat(ValorAdicional) + parseFloat(valor);

    AtualizaValor();
  };

  const remove = (valor) => {
    ValorAdicional = parseFloat(ValorAdicional) - parseFloat(valor);

    AtualizaValor();
  };

  function filtrarAdicionaisComQuantidadeNaoZero(dados) {
    const novoDadosSelecionado = { ...dados };

    if (novoDadosSelecionado.adicionais) {
      novoDadosSelecionado.adicionais = novoDadosSelecionado.adicionais.filter(
        (adicional) => adicional.quantidade_adicional !== 0
      );
    }

    return novoDadosSelecionado;
  }

  const AdicionaProduto = () => {
    setIsAdd(false);

    var Categorias = [];
    var categoriaLocalizada = 0;
    var adicionadoComSucesso = true;
    var TextoOpcoes = ";";
    var QuantidadeSabores = parseFloat(BuscaLocal("pizza_quantidade"));
    var ItensObrigatorios = false;

    if (QuantidadeSabores > 0) {
      var QuantidadeSaboresSelecionado = parseFloat(
        BuscaLocal("quantidade_sabor_selecionado")
      );
      var TipoSabor = parseFloat(BuscaLocal("pizza_tipo_valor"));

      if (QuantidadeSaboresSelecionado == 0) {
        Notificacao(
          "error",
          "Deve-se selecionar " + QuantidadeSabores + " sabore(s)"
        );
        adicionadoComSucesso = false;
        setIsAdd(true);
      } else if (
        TipoSabor !== 0 &&
        QuantidadeSabores !== QuantidadeSaboresSelecionado
      ) {
        Notificacao(
          "error",
          "Deve-se selecionar " + QuantidadeSabores + " sabore(s)"
        );

        adicionadoComSucesso = false;
        setIsAdd(true);
      }
    }

    if (dadosAdicionales) {
      for (let i = 0; i < dadosAdicionales.length; i++) {
        Categorias.push({
          id_categoria: dadosAdicionales[i]["id"],
          nome_categoria: dadosAdicionales[i]["name_adicionais_cat"],
          min_categoria: dadosAdicionales[i]["minimo"],
          max_categoria: dadosAdicionales[i]["qtd"],
          quantidade_selecionada: 0,
        });
      }
    }

    for (let i = 0; i < DadosSelecionado[0].adicionais.length; i++) {
      categoriaLocalizada = undefined;
      for (let j = 0; j < Categorias.length; j++) {
        if (
          Categorias[j].id_categoria ==
          DadosSelecionado[0].adicionais[i]["id_categoria"]
        ) {
          categoriaLocalizada = j;
          break;
        }
      }

      if (categoriaLocalizada == undefined) {
        Categorias.push({
          id_categoria: DadosSelecionado[0].adicionais[i]["id_categoria"],
          nome_categoria: DadosSelecionado[0].adicionais[i]["nome_categoria"],
          min_categoria: DadosSelecionado[0].adicionais[i]["min_categoria"],
          max_categoria: DadosSelecionado[0].adicionais[i]["max_categoria"],
          quantidade_selecionada:
            DadosSelecionado[0].adicionais[i]["quantidade_adicional"],
        });
      } else {
        Categorias[categoriaLocalizada].quantidade_selecionada =
          Categorias[categoriaLocalizada].quantidade_selecionada +
          DadosSelecionado[0].adicionais[i]["quantidade_adicional"];
      }
    }

    for (let j = 0; j < Categorias.length; j++) {
      TextoOpcoes = "opção";

      if (parseInt(Categorias[j]["min_categoria"]) > 0) {
        TextoOpcoes = "opções";

        if (
          Categorias[j]["quantidade_selecionada"] <
          Categorias[j]["min_categoria"]
        ) {
          adicionadoComSucesso = false;
          Notificacao(
            0,
            "Deve-se selecionar pelomenos " +
              Categorias[j]["min_categoria"] +
              " " +
              TextoOpcoes +
              " de " +
              PrimeiraLetraMaiuscula(Categorias[j]["nome_categoria"]) +
              "!"
          );
          setIsAdd(true);
          break;
        }
      }
    }

    DadosSelecionado[0] = filtrarAdicionaisComQuantidadeNaoZero(
      DadosSelecionado[0]
    );

    if (
      DadosSelecionado &&
      DadosSelecionado[0] &&
      DadosSelecionado[0]["pizza"] &&
      DadosSelecionado[0]["pizza"][0] &&
      DadosSelecionado[0]["pizza"][0]["max"]
    ) {
      if (DadosSelecionado[0]["pizza"][0]["tipo_valor"] == 0) {
        DadosSelecionado[0]["pizza"][0]["max"] = BuscaLocal(
          "soma_sabores_selecionado"
        );
      }
    } else {
    }

    DadosSelecionado[0]["quantidade_selecionada"] = Quantidade;

    if (adicionadoComSucesso) {
      var Produto = JSON.parse(BuscaLocal("produtos_selecionados"));

      ReactPixel.init(PIXELFACEBOOK());
      ReactPixel.trackCustom("AddToCart", {
        empresa: BuscaLocal("NOME_LINK_EMPRESA"),
        name: DadosSelecionado[0].nome_produto,
        ids: DadosSelecionado[0].id_produto,
      });

      if (Produto == null) {
        Produto = [];
      }

      Produto.push(DadosSelecionado[0]);

      GravaLocal("produtos_selecionados", JSON.stringify(Produto));

      Notificacao(
        "sucesso",
        DadosSelecionado[0]["quantidade_selecionada"] +
          "x " +
          DadosSelecionado[0]["nome_produto"] +
          " incluido com sucesso! 😍😍"
      );
      props.onClose();
      setIsAdd(true);
    }
  };

  const CarregaPizza = (props) => {
    try {
      return (
        <>
          <div
            className="header-adicional"
            style={{
              height: "36px",
              backgroundColor: CorFundo(),
              color: CorFonte(),
            }}
          >
            <span className="adicional-titulo">
              Selecione até {dadosPizza[0]["qtd_sabor"]}{" "}
              {dadosPizza[0]["qtd_sabor"] == 1 ? "Sabor" : "Sabores"}
            </span>
          </div>

          <div style={{ padding: "10px" }}>
            {/* Criar um mapa para agrupar os dados por tipo */}
            {(() => {
              const grupoPorTipo = {};
              // Percorre os dadosPizza para agrupar por tipo
              for (let i = 0; i < dadosPizza.length; i++) {
                const adicionais = dadosPizza[i];
                // Verifica se já existe um grupo com o tipo atual
                if (!grupoPorTipo[adicionais.tipo]) {
                  // Se não existir, cria um novo grupo com o tipo atual
                  grupoPorTipo[adicionais.tipo] = [];
                }
                // Adiciona os dados ao grupo correspondente ao tipo atual
                grupoPorTipo[adicionais.tipo].push(adicionais);
              }
              // Mapeia o mapa de grupos para renderizar os componentes
              return Object.entries(grupoPorTipo).map(([tipo, grupo]) => (
                <div key={tipo}>
                  <h2 style={{ textAlign: "center" }}>
                    {PrimeiraLetraMaiuscula(tipo)}
                  </h2>
                  {/* Mapeia os dados do grupo atual */}
                  {grupo.map((adicionais, index) => (
                    <CategoriaAdicional
                      key={adicionais.id}
                      dados={adicionais}
                      add={props.add}
                      remove={props.remove}
                      DadosSelecionado={DadosSelecionado}
                      tipo={"pizza"}
                    />
                  ))}
                </div>
              ));
            })()}
          </div>
        </>
      );
    } catch (error) {
      return null;
    }
  };

  const CarregaCategorias = (props) => {
    try {
      return dadosAdicionales.length > 0
        ? dadosAdicionales.map((adicionais) => (
            <CategoriaAdicional
              key={adicionais.id}
              dados={adicionais}
              add={props.add}
              remove={props.remove}
              DadosSelecionado={DadosSelecionado}
              tipo={"adicional"}
            />
          ))
        : null;
    } catch (error) {
      return null;
    }
  };

  if (dadosLocalProduto.length == 0) {
    API.get("produto.php?codigo=" + props.codigo).then(function (response) {
      ValorAdicional = 0;
      ValorProduto = parseFloat(response.data[0]["preco_item"]);
      ValorTotal = 0;
      Quantidade = 1;
      GravaLocal("produto_selecionado_dados", JSON.stringify(response.data));
      setDados(response.data);
      GravaLocal("produto_selecionado_valor", response.data[0]["preco_item"]);
      AtualizaValor();

      DadosSelecionado[0] = {
        id_produto: response.data[0]["id"],
        nome_produto: PrimeiraLetraMaiuscula(response.data[0]["nome_item"]),
        valor_produto: parseFloat(response.data[0]["preco_item"]),
        adicional_delivery_produto: parseFloat(
          response.data[0]["valor_delivery"]
        ),
        quantidade_selecionada: Quantidade,
        observacao_adicionada: "",
        url_foto: FotoProduto(response.data[0]["img_item"]),
        url_foto_ifood: response.data[0]["img_ifood"],
        adicionais: [],
        pizza: [],
      };
      console.log("categoria_adicionais.php?codigo=" + props.codigo);
      API.get("categoria_adicionais.php?codigo=" + props.codigo).then(function (
        response
      ) {
        setDadosAdicionales(response.data);
        SomaQuantidade();
        //////console.log(response.data)
        // document.getElementById('loader').style.display = 'none';
      });

      API.get("pizza.php?codigo=" + props.codigo).then(
        // API.get("pizza.php?codigo=89893").then(
        function (response) {
          setDadosPizza(response.data);
          DadosPizzaAlterado = response.data;
          SomaQuantidade();

          // document.getElementById('loader').style.display = 'none';
        }
      );
    });
  }

  return (
    <>
      {dados.map((produto) => (
        <>
          <div
            style={{
              borderBottom: "1px solid rgba(0,0,0,0.5)",
              padding: "10px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ width: "100px", height: "100px" }}>
                <ImagemProduto
                  foto={produto.img_item}
                  id={produto.id}
                  ifood={produto.img_ifood}
                />
              </div>
              <div
                style={{
                  justifyContent: "center",
                  width: "100%",
                  marginLeft: "10px",
                }}
              >
                <h4>{PrimeiraLetraMaiuscula(produto.nome_item)}</h4>
                <p>{PrimeiraLetraMaiuscula(produto.descricao_item)}</p>
                <h5 style={{ color: "black" }}>
                  {props.DadosPrecoProduto(produto)}
                </h5>
              </div>
            </div>
            <FidelidadeProduto data={produto} qtd={QTD} />
          </div>
        </>
      ))}

      <div
        style={{
          top: "100px",
          position: "flex",
          overflowY: "auto",
          top: "0",
          bottom: "0",
          left: "0",
          right: "0",
          height: "68%",
        }}
      >
        <CarregaPizza add={add} remove={remove} />
        <CarregaCategorias add={add} remove={remove} />

        <div className="input-observacao" style={{ marginBottom: "50px" }}>
          <InputTextarea
            onChange={handleChange}
            rows={5}
            cols={30}
            maxLength={MAX_CHARS}
            autoResize
            style={{ width: "100%" }}
          />
          <div id="contador-caracteres-observacao">
            200 caracteres restantes
          </div>
        </div>
      </div>
      {MensalidadePaga() && QuantidadeLoad == 2 ? (
        <>
          {isAdd ? (
            <>
              <div className="footer-buttons-categoria">
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "red",
                    display: "flex",
                    backgroundColor: "white",
                  }}
                >
                  <div
                    style={{ width: "70%", padding: "10px", height: "100%" }}
                    onClick={AdicionaProduto}
                  >
                    <div
                      style={{
                        fontSize: "1.3rem",
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                        borderRadius: "10px",
                        display: "flex", // Adicionado
                        alignItems: "center",
                        textAlign: "center",
                        backgroundColor: CorFundo(),
                        color: CorFonte(),
                        height: "50px",
                        justifyContent: "center",
                      }}
                    >
                      Adicionar
                      <span
                        style={{ fontSize: "1.3rem", margin: "3px" }}
                        id="total-produto"
                      ></span>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "30%",
                      padding: "10px",
                      marginRight: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "100px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                        borderRadius: "10px",
                      }}
                    >
                      <IoIosRemove
                        color={QTD > 0 ? CorFundo() : "transparent"}
                        size={48}
                        onClick={decrement}
                      />
                      <p
                        style={{
                          width: "33%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: 0,
                          fontSize: "1.2rem",
                        }}
                      >
                        <b id="qtd-produto">0</b>
                      </p>
                      <div
                        style={{
                          width: "33%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <IoIosAdd
                          color={CorFundo()}
                          size={48}
                          onClick={increment}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
}
