import { useState, memo } from "react";
import { formatoBrasileiro } from "../../../util/formatacaoMoeda";
import { Notificacao } from "../../../util/notificacao";
import { PrimeiraLetraMaiuscula } from "../../../util/primeiraLetraMaiuscula";
import { CorFundo } from "../../../util/validaAbertura";
import { IoIosAdd, IoIosRemove } from "react-icons/io";

function ItensAdicional(props) {
  const [value, setValue] = useState(0);

  const increment = () => {
    if (props.QuantidadeMaxima != props.QuantidadeAtual) {
      var AddCategoriaArray = true;
      var PosicaoCategoriaArray = 0;

      if (props.DadosSelecionado[0].adicionais.length == 0) {
        AddCategoriaArray = true;
      } else {
        for (var i = 0; i < props.DadosSelecionado[0].adicionais.length; i++) {
          if (
            props.DadosSelecionado[0].adicionais[i]["id_adicional"] ==
            props.dados["id_adicionais"]
          ) {
            AddCategoriaArray = false;
            PosicaoCategoriaArray = i;
            break;
          }
        }
      }

      if (AddCategoriaArray) {
        props.DadosSelecionado[0].adicionais.push({
          id_categoria: props.dados["id_adicionais_cat"],
          nome_categoria: PrimeiraLetraMaiuscula(props.categoria),
          id_adicional: props.dados["id_adicionais"],
          nome_adicional: PrimeiraLetraMaiuscula(props.dados["nome_adicional"]),
          valor_adicional: props.dados["valor_adicional"],
          min_categoria: props.QuantidadeMinima,
          max_categoria: props.QuantidadeMaxima,
          quantidade_adicional: 1,
        });
      } else {
        props.DadosSelecionado[0].adicionais[
          PosicaoCategoriaArray
        ].quantidade_adicional = value + 1;
      }
      props.Max();
      props.add(props.dados["valor_adicional"]);
      setValue(value + 1);
    } else {
      Notificacao(
        0,
        "Quantidade máxima de " + props.categoria + " já foi atingida!"
      );
    }
  };

  const decrement = () => {
    var Index = -1;
    var Achou = false;

    for (var i = 0; i < props.DadosSelecionado[0].adicionais.length; i++) {
      if (
        props.DadosSelecionado[0].adicionais[i]["id_adicional"] ==
        props.dados["id_adicionais"]
      ) {
        Index = i;
        Achou = true;
        break;
      }
    }

    if (value > 0) {
      ////////console.log(value)
      props.remove(props.dados["valor_adicional"]);
      props.Min();
      props.DadosSelecionado[0].adicionais[Index]["quantidade_adicional"] =
        value - 1;
      ////////console.log(props.DadosSelecionado[0].adicionais[Index]['quantidade_adicional'])
      setValue(value - 1);
    } else {
      if (Achou) {
        props.DadosSelecionado[0].adicionais[Index].splice(Index, 1);
      }
    }
  };

  return (
    <>
      <div
        className="input-with-buttons-container"
        id={props.key}
        style={{
          padding: "0px 10px 0px 10px",
          borderBottom: `1px dotted ${CorFundo()}`,
        }}
      >
        <div className="text-container">
          <h3>{PrimeiraLetraMaiuscula(props.dados["nome_adicional"])}</h3>
          <h5>{PrimeiraLetraMaiuscula(props.dados["descricao"])}</h5>
          {props.dados["valor_adicional"] > 0 ? (
            <h4
              style={{
                color: "#ccc",
              }}
            >
              {formatoBrasileiro.format(props.dados["valor_adicional"])}{" "}
            </h4>
          ) : null}
        </div>
        <div className="input-container">
          {/* <div className="buttons-container"> */}
          {/* {value > 0 ? <button className="decrement-button" onClick={decrement}>-</button> : null}
          <input className="input-box" type="text" value={value} disabled />
          <button className="increment-button" onClick={increment}>+</button> */}
          <div
            style={{
              width: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IoIosRemove
              color={value > 0 ? CorFundo() : "transparent"}
              size={36}
              onClick={decrement}
            />
            <p
              style={{
                width: "33%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: 0,
              }}
            >
              <b>{value}</b>
            </p>
            <div
              style={{
                width: "33%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IoIosAdd color={CorFundo()} size={36} onClick={increment} />
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
}

export default memo(ItensAdicional);
