import { useState } from "react";
import { GravaLocal } from "../../conexao/localstorage";
import { BuscaLocal } from "../../conexao/localstorageBusca";
import { CorFonte, CorFundo } from "../util/validaAbertura";
import { FaGift } from "react-icons/fa";
import {
  DescricaoProduto,
  PrimeiraLetraMaiuscula,
  truncateString,
} from "../util/primeiraLetraMaiuscula";
import { formatoBrasileiro, ValorProduto } from "../util/formatacaoMoeda";

export function DadosProduto({ product, setShowModal, flexDirectin }) {
  var Empresa = JSON.parse(BuscaLocal("dados_empresa"));
  let PontosDescontoFidelidade = Empresa[0].fidelidade_pontos
    ? parseInt(Empresa[0].fidelidade_pontos) == 1
    : false;

  const handleProdutoClick = (produto) => {
    if (produto != 0) {
      GravaLocal("soma_sabores", 0);
      GravaLocal("soma_sabores_selecionado", 0);
      GravaLocal("pizza_tipo_valor", 0);
      GravaLocal("produto_selecionado_dados", []);
      GravaLocal("produto_selecionada", produto);
      GravaLocal("quantidade_sabor_selecionado", 0);
      GravaLocal("pizza_quantidade", 0);
      handleOpenModal();
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  function Imagem() {
    if (product.img_ifood) {
      return product.img_ifood;
    } else {
      return `https://fotos.goopedir.com/fotos/${btoa(product.id)}`;
    }
  }

  return (
    <>
      <div
        style={{
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
          width: "100%",
          display: "flex",
          padding: "10px",
          flexDirection: flexDirectin ? flexDirectin : "row",
          borderRadius: "5px",
          wordWrap: "break-word",
          overflowWrap: "break-word",
          alignItems: "center",
        }}
        onClick={() => handleProdutoClick(product.id)}
      >
        <div
          style={{
            width: "150px",
            height: "150px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "150px",
            maxHeight: "150px",
          }}
        >
          <img
            src={Imagem()}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "10px",
              minHeight: "150px",
              maxHeight: "150px",
            }}
            alt="Produto"
          ></img>
        </div>
        <div style={{ marginLeft: "10px", flex: 1 }}>
          {product.fidelidade_valor > 0 && PontosDescontoFidelidade ? (
            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  top: -5,
                  right: -5,
                  backgroundColor: CorFundo(),
                  padding: "10px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "36px",
                  maxHeight: "36px",
                  maxWidth: "36px",
                  border: "1px solid " + CorFonte(),
                }}
              >
                <FaGift color={CorFonte()} size={18} />
              </div>
            </div>
          ) : null}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              // maxWidth: "calc(100% - 160px)", // subtracting the image width and margin
            }}
          >
            <h1 style={{ fontSize: "0.9rem" }}>
              <b>{PrimeiraLetraMaiuscula(product.nome_item)}</b>
            </h1>
            <p
              style={{
                wordWrap: "break-word",
                overflowWrap: "break-word",
              }}
            >
              {truncateString(
                DescricaoProduto(product.descricao_item, product.descricao2),
                80
              )}
            </p>
            <ValorProduto produto={product} />
          </div>
        </div>
      </div>
    </>
  );
}
